const routes = [
    {
        path: '/board',
        component: () => import('@/components/common/layout/CommonLayout'),
        children: [
            {
                path: 'notice',
                name: 'Notice',
                component: () => import('@/views/board/Notice'),
            },
            {
                path: 'notice/:bno/view',
                name: 'NoticeView',
                component: () => import('@/views/board/NoticeView'),
            },
            {
                path: 'notice/:bno',
                name: 'NoticeEdit',
                component: () => import('@/views/board/NoticeEdit'),
            },
        ],
    },
];

const setRoutes = root => {
    root.push(...routes);
};

export default setRoutes;
