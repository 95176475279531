const routes = [
    {
        path: '/statistics',
        component: () => import('@/components/common/layout/CommonLayout'),
        children: [
            {
                path: '',
                name: 'Statistics',
                component: () => import('@/views/statistics/Statistics'),
            },
        ],
    },
];

const setRoutes = root => {
    root.push(...routes);
};

export default setRoutes;
