// MUTATION
export const MUT_SET_DCDS = 'setDcds';
export const MUT_SET_LOADING = 'setLoading';

// ACTION
const state = {
    dcds: [],
    loading: {
        show: false,
        title: '',
    },
};

const mutations = {
    [MUT_SET_DCDS](state, dcds) {
        state.dcds = dcds;
    },
    [MUT_SET_LOADING](state, {show, title}) {
        state.loading.show = show;
        state.loading.title = title;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
