// MUTATION
export const MUT_SET_SESSION = 'setSession';
export const MUT_REMOVE_SESSION = 'removeSession';

export const MUT_SET_AUTH_TM = 'setAuthTm';

export const MUT_SET_BEFORE_ROUTE = 'setBeforeRoute';

// ACTION
const state = {
    authTm: 0,
    session: {
        name: null,
        email: null,
        manager: false,
    },
    menus: [
        {
            name: '사업소개',
            uri: '/intro',
            children: [],
            toggle: false,
        },
        {
            name: '데이터 허브',
            children: [
                {
                    name: '소개',
                    uri: '/hub/intro',
                },
                {
                    name: '데이터 레이크',
                    uri: '/hub/datalake',
                },
                {
                    name: '퍼블릭 데이터 마트',
                    uri: '/hub/public-mart',
                },
                {
                    // name: 'AI ・ 데이터 컨설팅',
                    name: '데이터 및 SW 품질 컨설팅',
                    uri: '/hub/consulting',
                },
                {
                    name: 'SW 융합사례',
                    uri: '/hub/conversion',
                },
                {
                    name: '시각화 분석사례',
                    uri: '/hub/visualization',
                },
                {
                    name: '애널리틱 분석사례',
                    uri: '/hub/analytics',
                },
            ],
            toggle: false,
        },
        {
            name: '사용가이드',
            children: [
                {
                    name: '애널리틱 서비스',
                    uri: '/guide/analytics',
                },
                {
                    name: '시각화 서비스',
                    uri: '/guide/visualization',
                },
                // {
                //     name: '데이터 샌드박스 활용',
                // },
            ],
            toggle: false,
        },
        {
            name: '데이터 샌드박스',
            children: [
                {
                    name: '이용신청',
                    uri: '/sandbox/apply',
                },
                {
                    name: '데이터 마트',
                    uri: '/sandbox/datamart',
                    condition: session => {
                        return !session.manager;
                    },
                },
                {
                    name: 'Athena',
                    uri: '/sandbox/athena',
                    hide: true,
                },
                {
                    name: 'AWS Glue',
                    uri: '/sandbox/glue',
                    hide: true,
                },
                {
                    name: 'AWS QuickSight',
                    uri: '/sandbox/quicksight',
                    hide: true,
                },
            ],
            toggle: false,
        },
        {
            name: '알림공간',
            uri: '/board/notice',
            children: [],
            // children: [
            //     {
            //         name: '공지사항',
            //         uri: '/board/notice',
            //     },
            //     {
            //         name: 'FAQ',
            //         hide: true,
            //     },
            // ],
            toggle: false,
        },
    ],
    beforeRoute: {
        name: null,
        uri: null,
    },
};

const mutations = {
    [MUT_SET_SESSION](state, {name, email, manager}) {
        state.session.name = name;
        state.session.email = email;
        state.session.manager = manager;
    },
    [MUT_REMOVE_SESSION](state) {
        state.session.name = null;
        state.session.email = null;
        state.session.manager = false;
    },
    [MUT_SET_BEFORE_ROUTE](state, {name, uri}) {
        state.beforeRoute.name = name;
        state.beforeRoute.uri = uri;
    },
    [MUT_SET_AUTH_TM](state, authTm) {
        state.authTm = authTm;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
