const routes = [
    {
        path: '/',
        component: () => import('@/components/common/layout/CommonLayout'),
        children: [
            {
                path: 'sign-in',
                name: 'SignIn',
                component: () => import('@/views/SignIn'),
            },
        ],
    },
];

const setRoutes = root => {
    root.push(...routes);
};

export default setRoutes;
