import ApiService from '@/assets/js/api.service';
import store from '@/store';
import {MUT_REMOVE_SESSION, MUT_SET_AUTH_TM, MUT_SET_BEFORE_ROUTE, MUT_SET_SESSION} from '@/store/modules/auth';

export const setAuthSession = async session => {
    await store.commit(`auth/${MUT_SET_SESSION}`, session);
};

export const removeAuthSession = async () => {
    await store.commit(`auth/${MUT_REMOVE_SESSION}`);
};

export const getAuthSession = () => {
    return store.state.auth.session;
};

export const getAuthTm = () => {
    return store.state.auth.authTm;
};

export const getAuth = () => {
    return ApiService.get('/v1/auths');
};

export const setBeforeRoute = async route => {
    await store.commit(`auth/${MUT_SET_BEFORE_ROUTE}`, route);
};

export const setAuthTm = async authTm => {
    await store.commit(`auth/${MUT_SET_AUTH_TM}`, authTm);
};

export const getMenuName = path => {
    if (path === '/') return '메인';
    const parents = store.state.auth.menus;
    let name = '';
    for (let i = 0; i < parents.length; i++) {
        if (parents[i].uri && path.indexOf(parents[i].uri) === 0) {
            name = parents[i].name;
            break;
        } else {
            const children = parents[i].children;
            for (let j = 0; j < children.length; j++) {
                if (children[j].uri && path.indexOf(children[j].uri) === 0) {
                    name = children[j].name;
                    break;
                }
            }
        }
    }
    return name;
};

const authRouteNames = ['DataMart', 'Glue', 'Athena', 'QuickSight', 'Manager'];

export const checkAuthRoute = name => {
    if (name && name.includes('Edit')) {
        return true;
    }

    for (let i = 0; i < authRouteNames.length; i++) {
        if (name && name.indexOf(authRouteNames[i]) === 0) {
            return true;
        }
    }
    return false;
};
