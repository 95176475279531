const routes = [
    {
        path: '/guide',
        component: () => import('@/components/common/layout/CommonLayout'),
        children: [
            {
                path: 'analytics',
                name: 'AnalyticsGuid',
                component: () => import('@/views/guide/AnalyticsGuid'),
            },
            {
                path: 'visualization',
                name: 'VisualizationGuid',
                component: () => import('@/views/guide/VisualizationGuid'),
            },
        ],
    },
];

const setRoutes = root => {
    root.push(...routes);
};

export default setRoutes;
