const routes = [
    {
        path: '/sandbox',
        component: () => import('@/components/common/layout/CommonLayout'),
        children: [
            {
                path: 'apply',
                name: 'Apply',
                component: () => import('@/views/sandbox/Apply'),
            },
            {
                path: 'datamart',
                name: 'DataMart',
                component: () => import('@/views/sandbox/DataMart'),
            },
            {
                path: 'athena',
                name: 'Athena',
                component: () => import('@/views/sandbox/Athena'),
            },
            {
                path: 'athena/:id',
                name: 'AthenaView',
                component: () => import('@/views/sandbox/AthenaView'),
            },
            {
                path: 'glue',
                name: 'Glue',
                component: () => import('@/views/sandbox/Glue'),
            },
            {
                path: 'glue/crawler/:crawlerName',
                name: 'GlueCrawler',
                component: () => import('@/views/sandbox/GlueCrawler'),
            },
            {
                path: 'glue/job/:jobName',
                name: 'GlueJob',
                component: () => import('@/views/sandbox/GlueJob'),
            },
            {
                path: 'quicksight',
                name: 'QuickSight',
                component: () => import('@/views/sandbox/QuickSight'),
            },
            // {
            //     path: 'quicksight/console',
            //     name: 'QuickSightEmbedded',
            //     component: () => import('@/views/QuickSightEmbedded'),
            // },
        ],
    },
];

const setRoutes = root => {
    root.push(...routes);
};

export default setRoutes;
