<template>
    <router-view />
</template>

<script>
import ApiService from '@/assets/js/api.service';
import {getItems, lengthCheck} from '@/assets/js/common.utils';
import {useStore} from 'vuex';
import {MUT_SET_DCDS} from '@/store/modules/common';

export default {
    name: 'App',
    setup() {
        const store = useStore();
        ApiService.query('/v1/codes', {}).then(res => {
            if (lengthCheck(res)) {
                store.commit(`common/${MUT_SET_DCDS}`, getItems(res));
            }
        });
    },
};
</script>

<style lang="css">
@import 'assets/css/page.common.css';
@import 'assets/css/page.main.css';
@import 'assets/css/page.biz.css';
@import 'assets/css/page.datasandbox.css';
@import 'assets/css/page.datahub.css';
@import 'assets/css/page.guide.css';
@import 'assets/css/swiper-bundle.min.css';

@import 'assets/css/developer.css';
</style>
