import {createApp} from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import ApiService from '@/assets/js/api.service';
import 'prismjs';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism-okaidia.css';
// import VueDaumPostcode from 'vue-daum-postcode';

const app = createApp(App);
ApiService.init(app);

app.use(store).use(router).mount('#swcluster');
// app.use(store).use(router).mount('#swcluster');

// window.popStateDetected = false;
// window.addEventListener('popstate', () => {
//     window.popStateDetected = true;
// });
