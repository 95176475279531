import axios from 'axios';
import VueAxios from 'vue-axios';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    vueInstance: null,
    init(app) {
        app.use(VueAxios, axios);
        this.vueInstance = app;
        this.setInterceptor();
    },
    setInterceptor() {
        this.vueInstance.axios.interceptors.response.use(
            function (response) {
                if (response && response.data && response.data.status) {
                    response.data.data.status = response.data.status;
                    return response.data.data;
                } else if (response.data) {
                    return response.data;
                } else {
                    return response;
                }
            },
            function (error) {
                return Promise.reject(error);
            },
        );
    },

    /**
     * Set the default HTTP request headers
     */
    query(resource, params) {
        return this.vueInstance.axios.get(resource, {params: params});
        // .catch(e => {
        //     console.error(e);
        //     // if(isReturn){
        //     // }
        // });
        // .catch(error => {
        //   throw new Error(`[KT] ApiService ${error}`);
        // });
    },
    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug) {
        return this.vueInstance.axios.get(`${resource}${slug ? `/${slug}` : ''}`);
        // .catch(e => {
        //     console.error(e);
        // });
    },
    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return this.vueInstance.axios.post(`${resource}`, params).catch(e => {
            console.error(e);
        });
    },
    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return this.vueInstance.axios.put(`${resource}/${slug}`, params);
        // .catch(e => {
        //     console.error(e);
        // });
    },
    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return this.vueInstance.axios.put(`${resource}`, params);
        // .catch(e => {
        //     console.error(e);
        // });
    },
    delete(resource, slug) {
        return this.vueInstance.axios.delete(`${resource}${slug ? `/${slug}` : ''}`);
        // .catch(e => {
        //     console.error(e);
        // });
    },
    upload(resource, fileList, isMulti) {
        return new Promise((resolve, reject) => {
            const data = new FormData();
            if (isMulti) {
                if (fileList && fileList.length > 0) {
                    for (let i = 0; i < fileList.length; i++) {
                        data.append('uploadFiles', fileList[i]);
                    }
                }
            } else {
                if (fileList && fileList.length > 0) {
                    data.append('uploadFile', fileList[0]);
                }
            }

            const xhr = new XMLHttpRequest();
            // timeout 을 길게
            xhr.timeout = 10 * 60 * 1000;
            xhr.open('POST', `${resource}`, true);
            xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
            xhr.responseType = 'json';

            xhr.addEventListener('load', () => {
                const response = xhr.response;
                if (!response || response.error) {
                    return reject(response && response.error ? response.error : 'Upload Failed');
                } else {
                    return resolve(response);
                }
            });

            xhr.send(data);
        });
    },
    download(resource, params, fileName) {
        const xhr = new XMLHttpRequest();
        let url = `${resource}`;
        if (params !== null && params !== undefined) {
            const keys = Object.keys(params);
            if (keys.length > 0) {
                url += '?' + keys.map(key => `${key}=${params[key]}`).join('&');
            }
        }
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.responseType = 'blob';
        xhr.onload = () => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(xhr.response);
            link.download = fileName;
            link.click();
        };
        xhr.send();
    },
};

export default ApiService;
