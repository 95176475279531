import store from '@/store';
import router from '@/router';
import ApiService from '@/assets/js/api.service';

// boardTypeDcd => 1002001 : 공지, 1002002 : 사업공지, 1002003 : 자료실, 1002004: : 보도자료
export const getDomainCodes = (dcd, exclusives) => {
    const filtered = store.state.common.dcds.filter(x => x.dcd && x.dcd.indexOf(dcd) === 0 && x.masterYn === 'N');
    return exclusives ? filtered.filter(x => !exclusives.includes(x.dcd)) : filtered;
};

export const getDomainCodeNm = dcd => {
    console.log(dcd);
    const found = store.state.common.dcds.find(x => x.dcd === dcd);
    return found ? found.dcdKoVal : '-';
};

export const goEdit = (name, key, value) => {
    const routerParams = {name: `${name}Edit`, params: {}};
    routerParams.params[key] = value;
    router.push(routerParams).then(() => {});
};

export const goBack = (e, path) => {
    if (e.ctrlKey) {
        window.open(path);
    } else {
        router.push(path).then(() => {});
    }
};

export const pageFunc = pageNo => {
    router.push({query: {pageNo: pageNo}}).then(() => {});
};

// {serviceName, servicePath}
export const insertLog = params => {
    ApiService.post('/v1/accesses/logs', params).then(() => {});
};
