const routes = [
    {
        path: '/hub',
        component: () => import('@/components/common/layout/CommonLayout'),
        children: [
            {
                path: 'intro',
                name: 'HubIntro',
                component: () => import('@/views/hub/HubIntro'),
            },
            {
                path: 'datalake',
                name: 'DataLakeOrigin',
                component: () => import('@/views/hub/DataLake'),
            },
            {
                path: 'datalake/:metaNo',
                name: 'DataLakeOriginEdit',
                component: () => import('@/views/hub/DataLakeEdit'),
            },
            {
                path: 'datalake/:metaNo/view',
                name: 'DataLakeOriginView',
                component: () => import('@/views/hub/DataLakeView'),
            },

            {
                path: 'public-mart',
                name: 'DataLakePublic',
                component: () => import('@/views/hub/DataLake'),
            },
            {
                path: 'public-mart/:metaNo',
                name: 'DataLakePublicEdit',
                component: () => import('@/views/hub/DataLakeEdit'),
            },
            {
                path: 'public-mart/:metaNo/view',
                name: 'DataLakePublicView',
                component: () => import('@/views/hub/DataLakeView'),
            },

            {
                path: 'consulting',
                name: 'Consulting',
                component: () => import('@/views/hub/Consulting'),
            },
            {
                path: 'consulting/apply',
                name: 'ConsultingApply',
                component: () => import('@/views/hub/ConsultingApply'),
            },
            {
                path: 'consulting/status',
                name: 'ConsultingStatus',
                component: () => import('@/views/hub/ConsultingStatus'),
            },
            {
                path: 'consulting/:consultingNo/view',
                name: 'ConsultingView',
                component: () => import('@/views/hub/ConsultingApply'),
            },

            // {
            //     path: 'consulting/:bno',
            //     name: 'ConsultingEdit',
            //     component: () => import('@/views/board/BoardEdit'),
            // },
            // {
            //     path: 'consulting/:bno/view',
            //     name: 'ConsultingView',
            //     component: () => import('@/views/board/BoardView'),
            // },

            {
                path: 'conversion',
                name: 'Conversion',
                component: () => import('@/views/board/Board'),
            },
            {
                path: 'conversion/:bno',
                name: 'ConversionEdit',
                component: () => import('@/views/board/BoardEdit'),
            },
            {
                path: 'conversion/:bno/view',
                name: 'ConversionView',
                component: () => import('@/views/board/BoardView'),
            },

            {
                path: 'visualization',
                name: 'Visualization',
                component: () => import('@/views/board/Board'),
            },
            {
                path: 'visualization/:bno',
                name: 'VisualizationEdit',
                component: () => import('@/views/board/BoardEdit'),
            },
            {
                path: 'visualization/:bno/view',
                name: 'VisualizationView',
                component: () => import('@/views/board/BoardView'),
            },

            {
                path: 'analytics',
                name: 'Analytics',
                component: () => import('@/views/board/Board'),
            },
            {
                path: 'analytics/:bno',
                name: 'AnalyticsEdit',
                component: () => import('@/views/board/BoardEdit'),
            },
            {
                path: 'analytics/:bno/view',
                name: 'AnalyticsView',
                component: () => import('@/views/board/BoardView'),
            },
        ],
    },
];

const setRoutes = root => {
    root.push(...routes);
};

export default setRoutes;
