const routes = [
    {
        path: '/',
        component: () => import('@/components/common/layout/CommonLayout'),
        children: [
            {
                path: '',
                name: 'Main',
                component: () => import('@/views/Main'),
            },
            {
                path: 'intro',
                name: 'Intro',
                component: () => import('@/views/Intro'),
            },
            {
                path: 'manager',
                name: 'Manager',
                component: () => import('@/views/member/Manager'),
            },
            {
                path: 'memberEdit/:mno',
                name: 'MemberEdit',
                component: () => import('@/views/member/MemberEdit'),
            },
        ],
    },
];

const setRoutes = root => {
    root.push(...routes);
};

export default setRoutes;
