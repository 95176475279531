import {createRouter, createWebHistory} from 'vue-router';
import setMainRoutes from '@/router/modules/main';
import setSandboxRoutes from '@/router/modules/sandbox';
import setSignRoutes from '@/router/modules/sign';
import setBoardRoutes from '@/router/modules/board';
import setHubRoutes from '@/router/modules/hub';
import setGuideRoutes from '@/router/modules/guide';
import setStatisticsRoutes from '@/router/modules/statistics';
// import setManagerRoutes from '@/router/modules/manager';
import {checkAuthRoute, getAuth, getAuthSession, getAuthTm, getMenuName, setAuthSession, setAuthTm, setBeforeRoute} from '@/assets/js/modules/auth/module';
import {getItem, lengthCheck} from '@/assets/js/common.utils';
import {insertLog} from '@/assets/js/modules/common/module';

const routes = [];

setMainRoutes(routes);
setSignRoutes(routes);
setHubRoutes(routes);
setGuideRoutes(routes);
setBoardRoutes(routes);
setSandboxRoutes(routes);
setStatisticsRoutes(routes);
// setManagerRoutes(routes);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const logExclusives = ['Statistics', 'SignIn'];

const checkStackLogPath = name => {
    if (name) {
        // 추후 어드민 로그로 사용할 예정
        return !name.includes('Edit') && !logExclusives.includes(name);
    }

    return false;
};

const nextWithLog = (to, next) => {
    if (to && to.path && checkStackLogPath(to.name)) insertLog({serviceNm: getMenuName(to.path), servicePath: to.path});
    next();
};

router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);
    const session = getAuthSession();
    if (checkAuthRoute(to.name)) {
        if (session.name !== null) {
            nextWithLog(to, next);
            // next();
        } else {
            try {
                const res = await getAuth();
                if (lengthCheck(res)) {
                    const item = getItem(res);
                    await setAuthSession(item);
                    nextWithLog(to, next);
                } else {
                    await setBeforeRoute({name: getMenuName(to.path), uri: to.path});
                    next({name: 'SignIn'});
                }
            } catch (_) {
                // console.error(e);
                await setBeforeRoute({name: getMenuName(to.path), uri: to.path});
                next({name: 'SignIn'});
            }
        }
    } else {
        if (session.name === null) {
            const authTm = getAuthTm();
            // 첫 페이지 접속 또는 이미 체크했을 경우 5분동안 체크하지 않느다.
            if (authTm === 0 || authTm < new Date().getTime()) {
                // session 조회시 check
                await setAuthTm(new Date().getTime() + 1000 * 60 * 5);

                try {
                    const res = await getAuth();
                    if (lengthCheck(res)) {
                        const item = getItem(res);
                        await setAuthSession(item);
                    }
                    // eslint-disable-next-line no-empty
                } catch (_) {}
            }
        }
        // }
        nextWithLog(to, next);
    }
});

export default router;
