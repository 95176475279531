export const lengthCheck = res => {
    return !!(res && res.items && res.items.length > 0);
};

export const collectionCheck = (items, key) => {
    return !!(items && items.length > 0 && items[0][key] > 0);
};

export const getItems = res => {
    return res.items;
};

export const getItem = res => {
    return res.items[0];
};

export const getCheckItems = res => {
    if (lengthCheck(res)) {
        return res.items;
    }
    return [];
};

export const isSuccess = res => {
    return !!(res && res.result && res.result.number === 200);
};

export const getResult = res => {
    if (res && res.result) {
        return res.result;
    }
    return {
        number: 500,
        message: '오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
    };
};

export const getPaging = res => {
    if (res && res.paging) {
        return res.paging;
    }
    return {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
        hasMore: false,
    };
};

export const getTotalCount = res => {
    return getPaging(res).totalCount;
};

export const getRoutePageNo = route => {
    return route.query.pageNo !== null && route.query.pageNo !== undefined ? parseInt(route.query.pageNo) : 1;
};

export const ymdStrToFormat = (ymdStr, format) => {
    return ymdStr.substr(0, 4) + format + ymdStr.substr(4, 2) + format + ymdStr.substr(6, 2);
};

export const hhmmStrToFormat = (hhmm, division) => {
    return hhmm.substr(0, 2) + division + hhmm.substr(2, 2);
};

export const ymdStrToDate = ymd => {
    return new Date(Number(ymd.substr(0, 4)), Number(ymd.substr(4, 2)) - 1, Number(ymd.substr(6, 2)));
};

export const timestampToDateFormat = (timestamp, format) => {
    const d = new Date(timestamp);
    return dateToDateFormat(d, format);
};

export const ymdStrToDateFormat = (ymd, format) => {
    return dateToDateFormat(ymdStrToDate(ymd), format);
};

const week = ['일', '월', '화', '수', '목', '금', '토'];

export const getWeekOfDay = idx => {
    return week[idx];
};

export const dateToDateFormat = (d, format) => {
    const yyyy = d.getFullYear(),
        mm = ('0' + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
        dd = ('0' + d.getDate()).slice(-2), // Add leading 0.
        w = week[d.getDay()],
        hh = d.getHours(),
        hour = ('0' + hh).slice(-2),
        min = ('0' + d.getMinutes()).slice(-2),
        sec = ('0' + d.getSeconds()).slice(-2);

    let h = hh,
        ampm = 'AM',
        AMPM = '오전';
    let time;
    if (hh > 12) {
        h = hh - 12;
        ampm = 'PM';
        AMPM = '오후';
    } else if (hh === 12) {
        h = 12;
        ampm = 'PM';
        AMPM = '오후';
    } else if (hh === 0) {
        h = 12;
    }
    if (format === 'yyyy-MM-dd') {
        time = yyyy + '-' + mm + '-' + dd;
    } else if (format === 'yyyy.MM.dd') {
        time = yyyy + '.' + mm + '.' + dd;
    } else if (format === 'yyyyMMdd') {
        time = yyyy + '' + mm + '' + dd;
    } else if (format === 'yyyy-MM-dd(w)') {
        time = `${yyyy}-${mm}-${dd} (${w})`;
    } else if (format === 'yyyyMMddhhmm') {
        time = `${yyyy}${mm}${dd}${hh}${mm}`;
    } else if (format === 'yyyy.MM.dd(w)') {
        time = `${yyyy}.${mm}.${dd} (${w})`;
    } else if (format === 'yyyy.MM.dd hh:mm') {
        time = yyyy + '.' + mm + '.' + dd + ' ' + hour + ':' + min;
    } else if (format === 'yyyy.MM.dd hh:mm:ss') {
        time = `${yyyy}.${mm}.${dd} ${hour}:${min}:${sec}`;
    } else if (format === 'yyyy-MM-dd, hh:mm') {
        time = yyyy + '-' + mm + '-' + dd + ', ' + hour + ':' + min;
    } else if (format === 'yyyy-MM-dd(w), hh:mm') {
        time = `${yyyy}-${mm}-${dd}(${w}), ${hh}:${min}`;
    } else if (format === 'yyyy.MM.dd AMPM hh:mm') {
        time = `${yyyy}.${mm}.${dd} ${AMPM} ${hh}:${min}`;
    } else if (format === 'hh:mm') {
        time = hh + ':' + min;
    } else if (format === 'hhmm') {
        time = hh + '' + min;
    } else if (format === 'hh:mm(A)') {
        time = h + ':' + min + '(' + ampm + ')';
    } else {
        time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
    }
    return time;
};

export const timestampToDatePart = timestamp => {
    return dateToDatePart(new Date(timestamp));
};

export const dateToDatePart = d => {
    const year = d.getFullYear(),
        month = d.getMonth() + 1,
        day = d.getDate(),
        mm = ('0' + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
        dd = ('0' + d.getDate()).slice(-2), // Add leading 0.
        dow = week[d.getDay()],
        // w = (week[d.getDay()]),
        hh = d.getHours(),
        min = ('0' + d.getMinutes()).slice(-2); // Add leading 0.
    let h = hh,
        ampm = 'AM';
    if (hh > 12) {
        h = hh - 12;
        ampm = 'PM';
    } else if (hh === 12) {
        h = 12;
        ampm = 'PM';
    } else if (hh === 0) {
        h = 12;
    }
    return {year, month, day, mm, dd, dow, hh, min, h, ampm, ymd: `${year}${mm}${dd}`};
};

export const phoneNumberMask = phoneNumber => {
    if (!phoneNumber) return phoneNumber;
    phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    let res = '';
    if (phoneNumber.length < 3) {
        res = phoneNumber;
    } else {
        if (phoneNumber.substr(0, 2) === '02') {
            if (phoneNumber.length <= 5) {
                //02-123-5678
                res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3);
            } else if (phoneNumber.length > 5 && phoneNumber.length <= 9) {
                //02-123-5678
                res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3) + '-' + phoneNumber.substr(5);
            } else if (phoneNumber.length > 9) {
                //02-1234-5678
                res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 4) + '-' + phoneNumber.substr(6);
            }
        } else {
            if (phoneNumber.length < 8) {
                res = phoneNumber;
            } else if (phoneNumber.length === 8) {
                res = phoneNumber.substr(0, 4) + '-' + phoneNumber.substr(4);
            } else if (phoneNumber.length === 9) {
                res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6);
            } else if (phoneNumber.length === 10) {
                res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6);
            } else if (phoneNumber.length > 10) {
                //010-1234-5678
                res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 4) + '-' + phoneNumber.substr(7);
            }
        }
    }
    return res;
};

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const convertToStorageBytes = x => {
    let n = parseInt(x, 10) || 0,
        l = 0;
    while (n > 1024) {
        n = n / 1024;
        l++;
    }
    return n.toFixed(n >= 10 ? 0 : 1) + ' ' + units[l];
};

export const paginate = (array, page_number, page_size) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const setParams = (params, item) => {
    Object.keys(params).forEach(key => {
        if (item[key] !== null && item[key] !== undefined) {
            params[key] = item[key];
        }
    });
};

export const initParams = params => {
    Object.keys(params).forEach(key => {
        if (params[key] instanceof Number) {
            params[key] = 0;
        } else if (params[key] instanceof Array) {
            params[key] = [];
        } else {
            params[key] = null;
        }
    });
};

export const goToTop = (targetId, _duration, _adjust) => {
    let targetEle;
    if (targetId instanceof String) {
        targetEle = document.getElementById(targetId);
    } else {
        targetEle = targetId;
    }

    if (!targetEle) return;

    // - Get current &amp; target positions
    const scrollEle = document.documentElement || window.scrollingElement,
        currentY = scrollEle.scrollTop,
        targetY = targetEle.offsetTop - (_adjust || 0);

    animateScrollTo(currentY, targetY, _duration);

    // - Animate and scroll to target position
    function animateScrollTo(_startY, _endY, _duration) {
        _duration = _duration ? _duration : 600;
        // _duration = 100;
        const unitY = (targetY - currentY) / _duration;
        const startTime = new Date().getTime();
        // const endTime = new Date().getTime() + _duration;
        const endTime = new Date().getTime() + _duration;
        const scrollTo = function () {
            let now = new Date().getTime();
            let passed = now - startTime;
            if (now <= endTime) {
                scrollEle.scrollTop = currentY + unitY * passed;
                requestAnimationFrame(scrollTo);
            }
        };
        requestAnimationFrame(scrollTo);
    }
};

export const numberComma = val => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// let internalInstance = null;
// let progressTimeout = null;
//
// export const initProgressBar = () => {
//     if (!internalInstance) internalInstance = getCurrentInstance();
//     if (internalInstance) {
//         if (progressTimeout) {
//             clearTimeout(progressTimeout);
//             progressTimeout = null;
//         }
//         internalInstance.appContext.config.globalProperties.$Progress.start();
//         progressTimeout = setTimeout(() => {
//             internalInstance.appContext.config.globalProperties.$Progress.finish();
//         }, 500);
//     }
//     //  [App.vue specific] When App.vue is first loaded start the progress bar
// };

export const firstUpperCase = str => {
    return str[0].toUpperCase() + str.slice(1, str.length);
};

export const getQueryVal = (query, isNumericValue, defaultValue) => {
    if (query) {
        return isNumericValue ? parseInt(query) : query;
    } else {
        if (defaultValue !== undefined) return defaultValue;
        return isNumericValue ? 0 : '';
    }
};

export const setQueryVal = (filters, query, configs) => {
    configs.forEach(config => {
        filters[config.key] = getQueryVal(query[config.key], config.isNum, config.default);
    });
};

export function dateDiff(start, end) {
    const startDate = ymdStrToDate(start);
    const endDate = ymdStrToDate(end);
    return Math.ceil(Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1;
}

export function tryCallback(stackCount, condition, callback, tryCount) {
    if (!tryCount) tryCount = 10;
    if (stackCount > tryCount) return;

    if (condition()) {
        callback();
        tryCount = 0;
    } else {
        stackCount++;
        setTimeout(() => {
            tryCallback(stackCount, condition, callback, tryCount);
        }, 300);
    }
}

export const getPageNo = (paging, index) => {
    return (paging.pageNo - 1) * paging.pageSize + index + 1;
};

export const fixedNumber = (target, len) => {
    if (target !== null && target !== undefined) {
        target = target + '';
        if (target.length > len) {
            target = target.substring(0, len);
        }
    }
    return target;
};

export const replaceAll = (str, searchStr, replaceStr) => {
    return str.split(searchStr).join(replaceStr);
};

export const getExt = (key, isLake) => {
    if (key.indexOf('.') !== -1) {
        if (isLake) {
            if (key.indexOf('기상청') > -1) return 'api';
        }
        return key.substring(key.lastIndexOf('.') + 1);
    }
    return null;
};

const _s4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

export const guid = len => {
    if (len > 0) {
        const share = len / 4;
        const uuids = [];
        for (let i = 0; i < share + 1; i++) {
            uuids.push(_s4());
        }
        return uuids.join('').substring(0, len);
    }
    return '';
};
